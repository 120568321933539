// $primary: #a51616;
$primary: #318AB2; /* MAIN COLOR */
$secondary: #ffc178; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa; 
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

h1, h2, h3 {font-family: 'Alfa Slab One', cursive;
			letter-spacing: 5px;}

a {font-family: 'Kreon', serif;}

p {font-family: 'Kreon', serif;}


.flash {
	display:none;
}

nav {
	z-index: 1000;
}

.navbar-default {background-color: $primary;
				 border-bottom: 15px double $secondary;
				 box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);}

.navbar .navbar-nav {
    > li > a {
		text-align: center;
		height: 80px;
		display: flex;
		align-items: center;
		color: $secondary;


		@media (max-width: 767px) {
		    height: 20px;
		    display: inline-block;
		}

	    &:hover, &:focus {
	    	background: transparent;
	    	color: $wht;
	    }
	}
}

.navbar-right {
	margin-top: 15px;
	font-size: 2.5em;

	@media (max-width:767px) {
		margin-top: 0px;
		font-size: 1.5em;

	}
	@media (max-width: 1024px) {
		margin-top: 0px;
		font-size: 1.5em;

	}
	@media (max-width: 1199px) {
		margin-top: 5px;
		font-size: 1.85em;

	}
}
 
.navbar-toggle {
    margin: 30px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
	color: $secondary;
	
	@media (max-width: 767px) {
		margin-top: 20px;
	}
}

.navbar-toggle {background-color: $secondary;}
.navbar-toggle:hover {background-color: $secondary !important;}


/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;
	
	.close {display: none;}

	.modal-content {
		color: $primary;
		
		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}
	
	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}
	
	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;	
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/

footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}

@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}


/* ========= BUTTONS ============ */

.btn-download,
.btn-download:focus,
.btn-download:active,
.btn-download:visited
{
    background-color:$primary;
    border-color: $wht;
	   color: $wht;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 19px;
    text-transform: uppercase;
    border-radius: 3px;
    transition: all 1s;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
     -o-transition: all 1s;
	 box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}
.btn-download:hover {
    background-color: $secondary;
    color: $primary;

}

.btn-download {
    margin-bottom: 15px !important;
    display: block;
    margin: 0 auto;
    max-width: 175px;
	margin-top: 50px;


	@media (max-width: 767px) {

		margin-top: 10px;
	}



	@media (max-width: 1024px) {

		margin-top: 10px;
	}
}


/* ======= HEADER ====== */

.header {
  background: url("../img/banner.jpg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  -webkit-background-size: cover;
}

@media (max-width: 767px) {
  .header {
    background-position: 50% 50%;
  }
}

.header-overlay {
  background-color: rgba(31, 31, 31, 0.5);
  padding: 100px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

@media (max-width: 767px) {
  .header-overlay {
    padding: 25px;
  }
}

@media (max-width: 1024px) {
  .header-overlay {
    padding: 15px;
  }
}

@media (max-width: 1199px) {
  .header-overlay {
    padding: 15px;
  }
}

.header h1 {
  color: $secondary;
  font-size: 2.5em;
  text-shadow: 3px 3px #000;
  margin-bottom: 50px;

@media (max-width: 767px) {
	margin-top: 50px;
	margin-bottom: 50px;
    font-size: 1.75em !important;
    text-align: center !important;
  }

@media (min-width: 768px) and (max-width: 1024px) {
    font-size: 2.5em;
    text-align: center !important;
  }
@media (max-width: 1199px) {
    font-size: 3.5em;
    text-align: left;
  }

}


.header p {
  color: #fafafa;
  font-size: 2em;
  letter-spacing: 2px;
  line-height: 50px;
  list-style: none;
  text-shadow: 2px 2px #000;
  margin-left: 20px;
}

@media (max-width: 767px) {
  .header p {
    font-size: 1.5em !important;
    text-align: center !important;
    line-height: 30px !important;
    margin-left: 0px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .header p {
    font-size: 1.5em;
    text-align: center !important;
    margin-left: 0px !important;
    line-height: 35px;
  }
}

@media (max-width: 1199px) {
  .header p {
    font-size: 1.75em;
    text-align: left;
    line-height: 40px;
  }
}

.logo {
  width: 100%;
  max-width: 320px;

	@media (max-width: 1024px) {
    width: 100%;
    max-width: 229px;
  }

}

@media (max-width: 1199px) and (orientation: landscape) {
  .logo {
    margin-top: 10px;
  }
}

.header img {display: block;
			 margin: 0 auto;
			 width: 100%;
			 max-width: 600px;
			 margin-bottom: 50px;
			 margin-top: 50px;}


.header p img {
			 box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
			 display: block;
			 margin: 0 auto;
			 width: 100%;
			 max-width: 600px;
			 margin-bottom: 50px;
	}


/* ======= Join Today ====== */

.join-today {background-color: $secondary; 
			 padding: 45px;

			 @media (max-width: 1024px) {
				 padding: 50px 0px;
			 }

}


.join-today h1 {color: $wht;
			font-size: 4em;
			margin-top: 10px;
			text-shadow: 3px 3px #318AB2;
			text-align: center;
			padding: 25px;

		@media (max-width: 1024px){
			font-size: 3.5em;
			margin: 0px;
			padding: 0px;
		}	

		@media (max-width: 1199px){
			padding-bottom: 25px;
		}	

	}


.join-today h3 {color: $wht;
			font-size: 2.5em;
			margin-top: 10px;
			text-shadow: 3px 3px #318AB2;
			
		    @media (max-width: 767px){
				font-size: 2em !important;
				text-shadow: none;
				margin: 0px;
				text-align: center !important;
				padding-top: 50px;
			}

		@media (min-width: 768px) and (max-width: 1023px) {
			text-align: center !important;
			padding-top: 50px;
		}	
		@media (max-width: 1199px) {
			text-align: left;
			font-size: 2.5em;
			margin: 0px;
		}	
}


.join-today p {color: $primary;
		   font-size: 1.5em;
		   letter-spacing: 2px;
		   line-height: 30px;
		   margin-left: 15px;

		   
		 
		 @media (max-width: 767px){
			font-size: 1.5em;
			text-align: center !important;
			line-height: 30px;
			margin-top: 25px;
			margin-left: 0px !important;
		}	

		@media (min-width: 768px) and (max-width: 1023px) {
			text-align: center !important;
		}
		   
		@media (max-width: 1199px) {
			text-align: left;
			line-height: 35px;
			margin-left: 0px;
		}

		   }


 @media (max-width: 768px) {
	 margin-top: 0px !important;
 }


 @media (max-width: 1024px){ 

	 		margin-top: 65px !important;
 }


/* ============= Why Us ================ */

.why-us {
		background-image: url('../img/lower_banner.jpg');
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		-webkit-background-size: cover;
		text-align: center;

		@media (max-width: 1024px) {
		
		background-repeat: repeat-y;
		background-size: inherit;
		-moz-background-size: inherit;
		-o-background-size: inherit;
		-webkit-background-size: inherit;

		}


}

.why-us-overlay {
    background-color: rgba(31, 31, 31, 0.75);
    padding: 0px;

	@media (max-width: 767px) {
		padding: 25px;
	}

	@media (max-width: 1024px) {
		padding: 15px;
	}
	@media (max-width: 1199px) {
		padding: 0px;
	}

}


.why-us h1 {color: $wht;
			font-size: 4em;
			margin-top: 10px;
			text-align: center;
			padding-top: 50px;
			padding-bottom: 50px;
		
		@media (max-width: 1024px){
			font-size: 3em;
			margin: 0px;
			padding-bottom: 50px;
		}			
	}


.why-us h3 {color: $secondary;
			font-size: 2.5em;
			margin-top: 10px;
			text-align: center;
			padding-top: 15px;
			padding-bottom: 15px;

		@media (max-width: 1024px){
			font-size: 3em !important;
			text-align: center;
			padding-bottom: 0px;
		}

		@media (max-width: 1199px){
			font-size: 1.85em;
		}
				
	}



.why-us p {color: $wht;
		   font-size: 1.5em;
		   letter-spacing: 1px;
		   line-height: 35px;
		   
		 
		 @media (max-width: 767px){
			font-size: 1.25em;
			text-align: center;
			line-height: 25px;
		 }	
		   
		   }


.why-us div i {color: $primary;
				 font-size: 200px;
    			 height: 200px;
				 display:inline-block;
				 line-height: 25px;
			     padding-top: 100px;
				 margin-bottom: 50px;

}

.why-us p img {
			 box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
			 display: block;
			 margin: 0 auto;
			 width: 100%;
			 max-width: 250px;
			 margin-bottom: 0px;


			 @media (max-width: 1024px) {
				 
				 max-width: 450px
			 }
	}


/* =========== Join Today CTA =========== */

.join-today-cta {background-color: $secondary; 
			 padding: 45px;
			 border-top: 15px double $wht;
			 border-bottom: 15px double $wht;


			@media (max-width: 1024px) {
				 padding: 50px 0px;
			 }

}


.join-today-cta h1 {color: $wht;
			font-size: 4em;
			margin-top: 10px;
			text-shadow: 3px 3px #318AB2;
			text-align: center;
			padding: 25px;

		@media (max-width: 1024px){
			font-size: 2.5em;
			text-align: center;
			padding: 0px;
		}	

		@media (max-width: 1199px){
			padding-bottom: 25px;
		}	

	}


.join-today-cta h2 {color: $wht;
			font-size: 2.5em;
			margin-top: 10px;
			margin-bottom: 25px;
			text-shadow: 3px 3px #318AB2;
			
		    @media (max-width: 767px){
				font-size: 1.75em !important;
				text-shadow: none;
				margin: 0px;
				text-align: center !important;
				padding-top: 50px;
			}

		@media (min-width: 768px) and (max-width: 1023px) {
			text-align: center !important;
			padding-top: 50px;
		}	
		@media (max-width: 1199px) {
			text-align: center;
			font-size: 2.5em;
			margin: 0px;
			padding-bottom: 25px;
		}	
}


.join-today-cta p {color: $primary;
		   font-size: 1.5em;
		   letter-spacing: 2px;
		   line-height: 30px;
		   margin-left: 15px;

		   
		 
		 @media (max-width: 767px){
			font-size: 1.5em;
			text-align: center !important;
			line-height: 30px;
			margin-top: 25px;
			margin-left: 0px !important;
		}	

		@media (min-width: 768px) and (max-width: 1023px) {
			text-align: center !important;
		}
		   
		@media (max-width: 1199px) {
			text-align: center;
			line-height: 35px;
			margin-left: 0px;
		}

		   }


 @media (max-width: 768px) {
	 margin-top: 0px !important;
 }


 @media (max-width: 1024px){ 

	 		margin-top: 65px !important;
 }